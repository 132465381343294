import { useRef, useState } from "react";

import { Checkbox } from "@mui/material";
import clsx from "clsx";

import AudioPlayer, {
  type AudioPlayerRef,
} from "@musicfy/components/AudioPlayer";
import Button from "@musicfy/components/Button";
import Dropdown, { type IDropdownOption } from "@musicfy/components/Dropdown";
import Icon from "@musicfy/components/Icon";
import Popover from "@musicfy/components/Popover";
import Switcher from "@musicfy/components/Switcher";
import { downloadFile } from "@musicfy/utils";

import { type IAudioSource } from "../types";

interface IGlobalAudioPlayerProviderProps {
  artists: IDropdownOption<string>[];
  audioPlayerRef: React.MutableRefObject<AudioPlayerRef | null>;
  audioSource: IAudioSource;
  audioSources: IAudioSource[];
  audioTypes: IDropdownOption<string>[];
  filenames: IDropdownOption<string>[];
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  selectedArtist: string;
  selectedAudioType: string;
  selectedFilename: string;
  setSelectedAudioSourceIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  autoPlay?: boolean;
}

type AudioSourceSearchableKeys = keyof Omit<IAudioSource, "value" | "id">;

function findAudioSourceIndex(
  audioSources: IAudioSource[],
  primaryKey: AudioSourceSearchableKeys,
  params: { artist: string; filename: string; label: string }
): number {
  let index = 0;

  let key2: AudioSourceSearchableKeys = primaryKey;
  let key3: AudioSourceSearchableKeys = primaryKey;

  Object.keys(params).forEach((paramKey) => {
    if (paramKey !== primaryKey) {
      key2 = paramKey as AudioSourceSearchableKeys;
    }
  });

  Object.keys(params).forEach((paramKey) => {
    if (paramKey !== primaryKey && paramKey !== key2) {
      key3 = paramKey as AudioSourceSearchableKeys;
    }
  });

  index = audioSources.findIndex((source) => {
    return (
      source[primaryKey] === params[primaryKey] &&
      source[key2] === params[key2] &&
      source[key3] === params[key3]
    );
  });

  if (index < 0) {
    index = audioSources.findIndex((source) => {
      return (
        source[primaryKey] === params[primaryKey] &&
        source[key2] === params[key2]
      );
    });
  }

  if (index < 0) {
    index = audioSources.findIndex((source) => {
      return (
        source[primaryKey] === params[primaryKey] &&
        source[key3] === params[key3]
      );
    });
  }

  if (index < 0) {
    index = audioSources.findIndex((source) => {
      return source[primaryKey] === params[primaryKey];
    });
  }

  return index >= 0 ? index : 0;
}

const DekstopGlobalAudioPlayer = ({
  artists,
  audioPlayerRef,
  audioSource,
  audioSources,
  audioTypes,
  filenames,
  isExpanded,
  onNextClick,
  onPrevClick,
  selectedArtist,
  selectedAudioType,
  selectedFilename,
  setSelectedAudioSourceIndex,
  setIsExpanded,
  autoPlay = false,
}: IGlobalAudioPlayerProviderProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false);
  const [audioSourcesToDownload, setAudioSourcesToDownload] =
    useState<IAudioSource[]>(audioSources);
  const downloadAllRef = useRef<HTMLDivElement | null>(null);

  const handleDownloadAllClick = async () => {
    setIsDownloadingFiles(true);
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await downloadFile({ audioSources: audioSourcesToDownload });
    setIsDownloadingFiles(false);
    setShowDownloadDialog(false);
  };

  const onDownloadFileClick = (audioSource: IAudioSource) => {
    setAudioSourcesToDownload((prev) => {
      if (prev.find((source) => source.id === audioSource.id)) {
        return prev.filter((source) => source.id !== audioSource.id);
      }
      return [...prev, audioSource];
    });
  };

  return (
    <>
      <div className="w-full flex relative items-stretch max-h-48 bg-gray-1000/90 backdrop-blur-2xl border-t border-white/15 px-6 py-6">
        <div className="absolute z-0 w-full overflow-x-hidden h-full bg-gradient-radial from-navy/70 to-navy/10 blur-lg top-0 left-0 pointer-events-none" />
        <div ref={containerRef} className="flex-grow">
          <div
            className={clsx(
              "transition-all flex gap-4 items-center duration-300 w-full",
              {
                "mb-4 opacity-100 max-h-28": isExpanded,
                "mb-0 opacity-0 max-h-0": !isExpanded,
              }
            )}
          >
            <div className="grid grid-cols-2 flex-grow gap-2 w-full">
              <div className="w-full">
                <Dropdown
                  fullWidth
                  options={artists}
                  value={selectedArtist}
                  setValue={(newArtist) => {
                    const audioSourceIndex = findAudioSourceIndex(
                      audioSources,
                      "artist",
                      {
                        artist: newArtist,
                        filename: selectedFilename,
                        label: selectedAudioType,
                      }
                    );
                    setSelectedAudioSourceIndex(audioSourceIndex);
                  }}
                />
              </div>
              <div className="w-full">
                <Dropdown
                  fullWidth
                  options={filenames}
                  value={selectedFilename}
                  setValue={(newFilename) => {
                    const audioSourceIndex = findAudioSourceIndex(
                      audioSources,
                      "filename",
                      {
                        artist: selectedArtist,
                        filename: newFilename,
                        label: selectedAudioType,
                      }
                    );

                    setSelectedAudioSourceIndex(audioSourceIndex);
                  }}
                />
              </div>
            </div>
            {audioTypes.length > 1 && (
              <div className="w-full max-w-fit flex-grow">
                <Switcher
                  labelClassName="!px-2 !py-2 !font-light !text-12"
                  options={audioTypes}
                  value={selectedAudioType}
                  setValue={(newAudioType) => {
                    const audioSourceIndex = findAudioSourceIndex(
                      audioSources,
                      "label",
                      {
                        artist: selectedArtist,
                        filename: selectedFilename,
                        label: newAudioType,
                      }
                    );
                    setSelectedAudioSourceIndex(audioSourceIndex);
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={clsx(
              "flex relative items-center rounded-12 border transition-all duration-300",
              {
                "p-4 bg-white/5 border-white/15 gap-6": isExpanded,
                "p-0 bg-white/0 border-transparent gap-12": !isExpanded,
              }
            )}
          >
            <div className="flex items-center justify-between flex-grow-0 flex-shrink-0">
              <div className="flex items-center gap-4">
                <div className="h-10 w-10 rounded-4 p-3 bg-gradient-radial from-white/0 to-white/3 border border-white/10 flex items-center justify-center">
                  <Icon name="logo" />
                </div>
                <div className="truncate max-w-40">
                  <div className="whitespace-nowrap text-14">
                    {audioSource.artist}
                  </div>
                  <div className="font-light text-white/70 text-12 mt-1 truncate">
                    {audioSource.filename}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <AudioPlayer
                ref={audioPlayerRef}
                autoPlay={autoPlay}
                audioSrc={audioSource.value}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                controlsPosition={isExpanded ? "right" : "top"}
                timePosition={isExpanded ? "right" : "top"}
                playButtonStyle={isExpanded ? "circle" : "full"}
                showRepeatButton={!isExpanded}
                controlSize="small"
              />
            </div>

            <Button
              onClick={() => downloadFile({ url: audioSource.value })}
              className="!rounded-full !min-h-[34px] !max-h-[34px] !max-w-[34px] !min-w-[34px] !px-0"
              variant="outlined"
              style="primary"
            >
              <div className="max-w-[34px] overflow-hidden rounded-full flex items-center justify-center">
                <Icon name="download" />
              </div>
            </Button>

            {audioSources.length > 1 && (
              <div
                onClick={() => setIsExpanded((prev) => !prev)}
                role="button"
                tabIndex={0}
                className="aspect-square border border-white/10 transition-all h-10 lg:flex hidden rounded-full items-center justify-center bg-gradient-radial from-white/0 to-white/3 xl:hover:border-white/20"
              >
                <Icon
                  name="expand"
                  className={clsx("transition-all", {
                    "-rotate-90": isExpanded,
                    "rotate-90": !isExpanded,
                  })}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={clsx(
            "transition-all flex flex-col justify-between gap-2 duration-300 overflow-y-auto border border-white/15 bg-white/5 backdrop-blur-md rounded-8",
            {
              "opacity-100 w-[25%] p-3 ml-4 max-h-72": isExpanded,
              "w-[0%] opacity-0 ml-0 max-h-0 overflow-hidden": !isExpanded,
            }
          )}
        >
          {audioSources.map((source, index) => {
            const artist = source.artist ?? "";
            const filename = source.filename ?? "";
            const label = source.label ?? "";
            const key = `${artist}-${filename}-${label}`;

            const isCurrentlyPlaying =
              artist === selectedArtist && filename === selectedFilename;

            const previousFilename = audioSources[index - 1]?.filename ?? "";
            const previousArtist = audioSources[index - 1]?.artist ?? "";

            if (previousFilename === filename && previousArtist === artist) {
              return null;
            }

            const isLastDistinctAudioSource =
              audioSources.findIndex((source, i) => {
                return (
                  (source.artist !== artist || source.filename !== filename) &&
                  i > index
                );
              }) < 0;

            return (
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (isCurrentlyPlaying) {
                    return;
                  }

                  setSelectedAudioSourceIndex(index);
                }}
                key={key}
                className={clsx(
                  "text-12 group flex items-center justify-between border-box cursor-pointer",
                  {
                    "border-b pb-2 border-white/20": !isLastDistinctAudioSource,
                  }
                )}
              >
                <div
                  className={clsx({
                    "w-3/4": isCurrentlyPlaying,
                    "w-full": !isCurrentlyPlaying,
                  })}
                >
                  <div className="flex items-center gap-1">
                    <div
                      className={clsx("truncate transition-all", {
                        "text-pink-200": isCurrentlyPlaying,
                        "text-white xl:hover:text-pink-100":
                          !isCurrentlyPlaying,
                      })}
                    >
                      {artist}
                    </div>
                  </div>
                  <div
                    className={clsx("font-light text-10 mt-[2px] truncate", {
                      "text-pink-200/80": isCurrentlyPlaying,
                      "text-white/60": !isCurrentlyPlaying,
                    })}
                  >
                    {filename}
                  </div>
                </div>
                {isCurrentlyPlaying && <Icon name="rhythm" />}
              </div>
            );
          })}
          <div
            className="sticky bottom-0 left-0 w-full flex justify-center items-center"
            ref={downloadAllRef}
          >
            <Button
              onClick={() => setShowDownloadDialog(true)}
              className="rounded-8 !py-2 w-full"
              variant="outlined"
              style="primary"
            >
              <div className="flex justify-center items-center text-12">
                <div>Download All</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <Popover
        open={showDownloadDialog && !!downloadAllRef}
        anchorEl={downloadAllRef.current}
        onClose={() => setShowDownloadDialog(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {audioSources.map((currentAudioSource) => (
          <div
            role="button"
            tabIndex={0}
            key={currentAudioSource.id}
            onClick={() => onDownloadFileClick(currentAudioSource)}
          >
            <div className="cursor-pointer flex items-center gap-2">
              <Checkbox
                sx={{
                  color: "#fff",
                  "&.Mui-checked": {
                    color: "#FE7ED9",
                  },
                }}
                id={currentAudioSource.id}
                name={currentAudioSource.id}
                value={currentAudioSource.id}
                checked={
                  !!audioSourcesToDownload.find(
                    (source) => source.id === currentAudioSource.id
                  )
                }
              />
              <div>{`${currentAudioSource.artist as string}-${
                currentAudioSource.label as string
              }-${currentAudioSource.filename as string}`}</div>
            </div>
          </div>
        ))}
        <Button
          variant="outlined"
          className="my-2 w-full !mx-auto !py-2"
          onClick={handleDownloadAllClick}
          loading={isDownloadingFiles}
        >
          Download Files
        </Button>
      </Popover>
    </>
  );
};

export default DekstopGlobalAudioPlayer;
