import { SubscriptionPlans } from "@prisma/client";

import { MODEL_TRAINING_MAX_QUOTA } from "@musicfy/contants/Features";

export const VOICE_TRAINING_HEADERS = [
  "Voice Training",
  "Voices",
  "Quality",
  "Retries",
  "Vocal Cleanup",
  "Training Speed",
  "Simultaneous Training",
  "Dataset Length",
];
export const VOICE_TRAINING_FEATURES = [
  {
    plan: SubscriptionPlans.free,
    voices: MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.free],
    quality: undefined,
    speed: undefined,
    simaltaneousTrains: undefined,
    trains: undefined,
    datasetLength: undefined,
    vocalEnhancement: undefined,
    color: "#FFFFFF",
  },
  {
    plan: SubscriptionPlans.starter,
    voices: MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.starter],
    quality: "High-Fidelity",
    speed: "Standard",
    simaltaneousTrains: undefined,
    datasetLength: "10 Minutes",
    trains: "Unlimited",
    vocalEnhancement: false,
    color: "#FE7ED9",
  },
  {
    plan: SubscriptionPlans.pro,
    voices: MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.pro],
    quality: "High-Fidelity",
    speed: "Standard",
    simaltaneousTrains: undefined,
    datasetLength: "30 Minutes",
    trains: "Unlimited",
    vocalEnhancement: true,
    color: "#FF95A9",
  },
  {
    plan: SubscriptionPlans.studio,
    voices: MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.studio],
    quality: "High-Fidelity",
    speed: "Fastest",
    simaltaneousTrains: true,
    datasetLength: "1 Hour",
    trains: "Unlimited",
    vocalEnhancement: true,
    color: "#CE7EFF",
  },
];

export const AUDIO_GENERATION_HEADERS = [
  "Audio Generations",
  "Voice Cloning",
  "Text to Music",
  "Conversion Speed",
  "Remove Instrumentals",
  "Access to All Voices",
  "Enhance Vocals",
  "Master Quality",
];
export const AUDIO_GENERATION_FEATURES = [
  {
    plan: SubscriptionPlans.free,
    voiceCloning: "3",
    textToMusic: "3",
    speed: "Standard",
    voiceAccess: undefined,
    masterQuality: undefined,
    removeInstrumentals: true,
    enhanceVocals: undefined,
    color: "#FFFFFF",
  },
  {
    plan: SubscriptionPlans.starter,
    voiceCloning: "Unlimited",
    textToMusic: "Unlimited",
    speed: "Fast",
    voiceAccess: true,
    masterQuality: true,
    removeInstrumentals: true,
    enhanceVocals: true,
    color: "#FE7ED9",
  },
  {
    plan: SubscriptionPlans.pro,
    voiceCloning: "Unlimited",
    textToMusic: "Unlimited",
    speed: "Fast",
    voiceAccess: true,
    masterQuality: true,
    removeInstrumentals: true,
    enhanceVocals: true,
    color: "#FF95A9",
  },
  {
    plan: SubscriptionPlans.studio,
    voiceCloning: "Unlimited",
    textToMusic: "Unlimited",
    speed: "Fastest",
    voiceAccess: true,
    masterQuality: true,
    removeInstrumentals: true,
    enhanceVocals: true,
    color: "#CE7EFF",
  },
];

export const QUESTIONS = [
  {
    question: "What is the difference between standard and master quality?",
    answer:
      "The main difference between standard and master quality is the pitch detection we use. Master quality will be far more accurate in converting the pitch between two voices. Visit <a style='text-decoration: underline;' href='https://musicfy.lol/standard-vs-master' target='_blank'>https://musicfy.lol/standard-vs-master</a> to view an example.",
  },
  {
    question: "What if I want to train my voice again?",
    answer:
      "If for whatever reason you are not satisfied with your voice, you can delete and train the voice again as many times as you like. The maximum number of voices you can have at once is determined by your plan.",
  },
  {
    question: "What does enhance vocals do?",
    answer:
      "Selected the 'Enhance Vocals' option will upsample the audio and remove any reverb or echo.",
  },
  {
    question: "Are my generations available for commercial use?",
    answer:
      "If your plan includes a commercial lisence you are free to use your generations for commercial use. You fully own the rights to your generations, as long as the voice you used was trained on copyright free data. All Musicfy models are trained on copyright free data.",
  },
  {
    question: "Does this work in every language?",
    answer:
      "Yes this works in every language. We do not convert your words, rather we convert the notes and pitch of the voice.",
  },
  {
    question: "How do I cancel my plan?",
    answer:
      "You can cancel your plan at any time and keep access until the end of your billing period. To manage your plan, visit account settings.",
  },
  {
    question: "Can I change my plan after I subscribe?",
    answer:
      "You can upgrade or downgrade your plan at any time by visiting account settings.",
  },
  {
    question: "Do you have a refund policy?",
    answer:
      "Visit <a style='text-decoration: underline;' href='https://musicfy.lol/refund' target='_blank'>https://musicfy.lol/refund</a> to view our refund policy.",
  },
];
