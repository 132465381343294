import { useCallback, useEffect, useState } from "react";

import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import { TextareaAutosize } from "@mui/material";
import { usePostHog } from "posthog-js/react";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

import { Reason, Step } from "../constants";

interface ConfirmStepProps {
  setStep: (step: Step) => void;
  onClose: () => void;
  showDiscountStep?: boolean;
  cancellationReason: string | Reason;
  setCancellationReason: (reason: string | Reason) => void;
}

const ConfirmCancellation = ({
  setStep,
  showDiscountStep = true,
  onClose,
  cancellationReason,
  setCancellationReason,
}: ConfirmStepProps): JSX.Element => {
  const posthog = usePostHog();

  const { subscription, cancelSubscription } = useSubscriptionContext();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    if (cancellationReason === Reason.OTHER) {
      setCancellationReason("");
    }

    posthog.capture("Subscription Cancel Step Viewed", {
      plan: subscription?.plan,
      interval: subscription?.interval,
      step: "confirm-cancel",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelSubscription = useCallback(
    async (reason: string) => {
      if (!reason) {
        setErrorMessage("Please enter a reason for cancelling.");
        return;
      }

      if (!subscription) {
        return;
      }

      setIsCancelling(true);
      await cancelSubscription(reason);
      setIsCancelling(false);
      onClose();
    },
    [cancelSubscription, onClose, subscription]
  );

  return (
    <div className="relative">
      <div className="bg-white/3 relative flex items-center overflow-hidden gap-3 text-white p-3">
        <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
        <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
        <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
        <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
        <HeartBrokenIcon className="!text-20 relative z-20" />
        <div className="font-bold relative z-20">Feedback</div>
      </div>
      <div className="px-5 mt-6">
        <div className="font-bold text-22">Sorry to see you go.</div>
        <div className="mt-3 text-gray-300 tracking-wide">
          We want to make the best product possible. We&apos;d appreciate your
          honest feedback.
        </div>
      </div>
      <div className="mt-6 overflow-hidden relative rounded-8 px-5">
        <TextareaAutosize
          disabled={isCancelling}
          className="min-h-8 w-full resize-none rounded-8 bg-white/4 transition-all border border-white/10 xl:hover:border-white/30 lg:p-5 p-4"
          placeholder="Describe reason for cancellation :("
          minRows={3}
          onChange={(e) => {
            setErrorMessage("");
            setCancellationReason(e.target.value);
          }}
          value={cancellationReason}
        ></TextareaAutosize>
      </div>
      {!!errorMessage && (
        <div className="px-5 text-red mt-2 text-center">{errorMessage}</div>
      )}
      <Divider className="my-4" />
      <div className="px-3 pb-4 flex items-center justify-between">
        <Button
          variant="outlined"
          className="!outline-transparent !bg-transparent"
          style="secondary"
          onClick={() => {
            if (showDiscountStep) {
              setStep(Step.CANCEL_REASON);
            } else {
              onClose();
            }
          }}
        >
          {showDiscountStep ? "Back" : "Close"}
        </Button>
        <Button
          variant="outlined"
          className="!outline-transparent"
          style="secondary"
          onClick={() => handleCancelSubscription(cancellationReason.trim())}
          loading={isCancelling}
        >
          Cancel Subscription
        </Button>
      </div>
    </div>
  );
};

export default ConfirmCancellation;
