/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  type Subscription,
  SubscriptionPlans,
  SubscriptionIntervals,
} from "@prisma/client";

import { MODEL_TRAINING_MAX_QUOTA } from "@musicfy/contants/Features";
import { env } from "@musicfy/env.mjs";
import { type PricePreviewData } from "@musicfy/libs/PaddleProvider/types";
import { formatPrice } from "@musicfy/utils";

export type AvailablePlans = Exclude<SubscriptionPlans, "free">;

export const isAvailablePlan = (plan: string): plan is AvailablePlans => {
  return (
    plan === SubscriptionPlans.starter ||
    plan === SubscriptionPlans.pro ||
    plan === SubscriptionPlans.studio
  );
};

export const AdditionalVoicesId = {
  voices_month:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01j34nd6krp6kzat42zcjs6f8d"
      : "pri_01j348t4htvm3188mb4dh5dyjj",
  voices_year:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01j34ncea5s94c4dstvtj3cqb5"
      : "pri_01j348v7kyyv1pwx95pxspks32",
} as const;

export const DiscountId = {
  retain_monthly:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "dsc_01hn1t9tfjg0wtg18qg5jpha54"
      : "dsc_01hmwf2q7wakf76nextgae7msx",
  retain_yearly:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "dsc_01hn1tatz2znyfrmyjs7nq365v"
      : "dsc_01hmwf6j187dp2et6k5mhj72gj",
} as const;

export const ProductId = {
  starter:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pro_01hfp2a6v3rdft82wvh55v9tbm"
      : "pro_01hdjdh7exba998bqay8hwd6w9",
  pro:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pro_01hfp2b1sj7zz27wt1a16m619k"
      : "pro_01hdjdn18fn00k66k47qqsjpnj",
  studio:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pro_01hfp2btwm66g7r160j2056845"
      : "pro_01hdjdq0bccgbeymk2fv7jkmyk",
  label:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pro_01j34n8n0nbecfbdadzs683m46"
      : "pro_01j346qpsn6wnyk2enxhpj1yvc",
} as const;

export const PriceId = {
  starter_month:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01hfp2nvbqv0z5nqsv696qcd3y"
      : "pri_01hdjdjcnymdsej6fgn1k31dnz",
  starter_year:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01hfp2mbs0tt0h9akpy97k7679"
      : "pri_01hdjdkm1f54z6zf0th5ywfp6e",
  pro_month:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01hfp2hya0f0k6v29f801pr54d"
      : "pri_01hdjdnq8th3yn8rfsjjm2w2jf",
  label_month:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01j34nan2n5gfsthpmaen35jvg"
      : "pri_01j346tq4haj4xfpd615wn92xt",
  pro_year:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01hfp2gx22mfstde3tbmsyx7c3"
      : "pri_01hdjdpa4k26g4me29bkqd8qwr",
  studio_month:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01hfp2ej14thcaj8bqfx2ak9ka"
      : "pri_01hdjdqjnmh8gyjnsemhdveenw",
  studio_year:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01hfp2d6b8ga6v9tc0v3d3yjmh"
      : "pri_01hdjdrgr81q680y6se3d1yr72",
  label_year:
    env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "pri_01j34n9jg45e4dcax4c2mdcx8w"
      : "pri_01j346x4kj5y3z00vehn718hsj",
} as const;

export type TPriceId = ValueOf<typeof PriceId>;
export type TProductId = ValueOf<typeof ProductId>;
export type TAdditionalVoicesId = ValueOf<typeof AdditionalVoicesId>;

export const PRICE_ID_TO_PLAN_MAP = {
  [PriceId.starter_month]: SubscriptionPlans.starter,
  [PriceId.starter_year]: SubscriptionPlans.starter,
  [PriceId.pro_month]: SubscriptionPlans.pro,
  [PriceId.pro_year]: SubscriptionPlans.pro,
  [PriceId.studio_month]: SubscriptionPlans.studio,
  [PriceId.studio_year]: SubscriptionPlans.studio,
  [PriceId.label_month]: SubscriptionPlans.label,
  [PriceId.label_year]: SubscriptionPlans.label,
} as { [x in TPriceId]: AvailablePlans };

export const PRICE_ID_TO_INTERVAL_MAP = {
  [PriceId.starter_month]: SubscriptionIntervals.month,
  [PriceId.starter_year]: SubscriptionIntervals.year,
  [PriceId.pro_month]: SubscriptionIntervals.month,
  [PriceId.pro_year]: SubscriptionIntervals.year,
  [PriceId.studio_month]: SubscriptionIntervals.month,
  [PriceId.studio_year]: SubscriptionIntervals.year,
  [PriceId.label_month]: SubscriptionIntervals.month,
  [PriceId.label_year]: SubscriptionIntervals.year,
} as { [x in TPriceId]: SubscriptionIntervals };

export const PRICE_ID_TO_PRICE_MAP = {
  [PriceId.starter_month]: 999,
  [PriceId.starter_year]: 9599,
  [PriceId.pro_month]: 2499,
  [PriceId.pro_year]: 23999,
  [PriceId.studio_month]: 6999,
  [PriceId.studio_year]: 67199,
  [PriceId.label_month]: 14999,
  [PriceId.label_year]: 133999,
} as { [x in TPriceId]: number };

export const PRODUCT_ID_TO_PLAN_MAP = {
  [ProductId.starter]: SubscriptionPlans.starter,
  [ProductId.pro]: SubscriptionPlans.pro,
  [ProductId.studio]: SubscriptionPlans.studio,
  [ProductId.label]: SubscriptionPlans.label,
} as { [x in TProductId]: AvailablePlans };

export const SubscriptionValueMap: Record<SubscriptionPlans, number> = {
  [SubscriptionPlans.free]: 0,
  [SubscriptionPlans.starter]: 1000,
  [SubscriptionPlans.pro]: 2000,
  [SubscriptionPlans.studio]: 5000,
  [SubscriptionPlans.label]: 6000,
};

export type TBasePlanInfo = {
  productId: ValueOf<TProductId>;
  label: string;
  benefits: string;
  speed: string;
  quality: string;
  customModel: string | undefined;
  generations: string;
  fileSizeLimit: string;
  plan: AvailablePlans;
};

export type PriceDetails = TBasePlanInfo & {
  priceId: TPriceId;
  interval: SubscriptionIntervals;
  price: number;
};

export const SubscriptionPlanInfo: Record<AvailablePlans, TBasePlanInfo> = {
  [SubscriptionPlans.starter]: {
    plan: SubscriptionPlans.starter,
    productId: ProductId.starter,
    label: "Starter",
    benefits: `${MODEL_TRAINING_MAX_QUOTA[
      SubscriptionPlans.starter
    ].toString()} Custom Voices`,
    speed: "Standard",
    quality: "Standard",
    customModel: MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.starter].toString(),
    generations: "Unlimited Generations",
    fileSizeLimit: "25 MB",
  },
  [SubscriptionPlans.pro]: {
    plan: SubscriptionPlans.pro,
    productId: ProductId.pro,
    label: "Professional",
    benefits: `${MODEL_TRAINING_MAX_QUOTA[
      SubscriptionPlans.pro
    ].toString()} Custom Voices`,
    speed: "Fastest",
    quality: "Premium",
    customModel: MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.pro].toString(),
    generations: "Unlimited Generations",
    fileSizeLimit: "100 MB",
  },
  [SubscriptionPlans.studio]: {
    plan: SubscriptionPlans.studio,
    productId: ProductId.studio,
    label: "Studio",
    benefits: `${MODEL_TRAINING_MAX_QUOTA[
      SubscriptionPlans.studio
    ].toString()} Custom Voices`,
    speed: "Fastest",
    quality: "Premium",
    customModel: MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.studio].toString(),
    generations: "Unlimited Generations",
    fileSizeLimit: "150 MB",
  },
  [SubscriptionPlans.label]: {
    plan: SubscriptionPlans.label,
    productId: ProductId.label,
    label: "Label",
    benefits: `100+ Custom Voices`,
    speed: "Fastest",
    quality: "Premium",
    customModel: "Unlimited",
    generations: "Unlimited Generations",
    fileSizeLimit: "500 MB",
  },
};

type PriceDetailsIdParams = {
  plan: AvailablePlans;
  interval: SubscriptionIntervals;
  priceId?: never;
};

type PriceDetailsPlanParams = {
  plan?: never;
  interval?: never;
  priceId: TPriceId;
};

type PriceDetailsParams = PriceDetailsIdParams | PriceDetailsPlanParams;

export const getSubscriptionPriceDetails = ({
  plan,
  interval,
  priceId,
}: PriceDetailsParams): PriceDetails => {
  if (priceId) {
    const plan = PRICE_ID_TO_PLAN_MAP[priceId];
    const interval = PRICE_ID_TO_INTERVAL_MAP[priceId];
    const price = PRICE_ID_TO_PRICE_MAP[priceId];

    const planInfo = SubscriptionPlanInfo[plan];

    return {
      ...planInfo,
      priceId,
      interval,
      price,
    };
  } else {
    const priceIdKey = `${plan}_${interval}` as const;
    const priceId = PriceId[priceIdKey];

    const planInfo = SubscriptionPlanInfo[plan];
    const price = PRICE_ID_TO_PRICE_MAP[priceId];

    return {
      ...planInfo,
      priceId,
      interval: interval,
      price,
    };
  }
};

export const getSubscriptionValue = (plan: SubscriptionPlans): number => {
  return SubscriptionValueMap[plan];
};

export const hasMinimumSubscriptionPlan = (
  plan: SubscriptionPlans | undefined,
  minimumPlan: SubscriptionPlans
): boolean => {
  return (
    SubscriptionValueMap[plan || SubscriptionPlans.free] >=
    SubscriptionValueMap[minimumPlan]
  );
};

export const getNextSubscriptionTier = (
  subscription: Subscription | null
): SubscriptionPlans | undefined => {
  const { plan } = subscription || { plan: SubscriptionPlans.free };

  let nextPlanTier;
  const currentPlanValue = SubscriptionValueMap[plan];
  const maxPlanValue = SubscriptionValueMap[SubscriptionPlans.studio];

  const entries = Object.entries(SubscriptionValueMap) as Entries<
    typeof SubscriptionValueMap
  >;

  for (const [key, value] of entries) {
    if (value > currentPlanValue && value <= maxPlanValue) {
      nextPlanTier = key;
      break;
    }
  }
  return nextPlanTier;
};

export function getFormattedPrice(
  priceId: TPriceId,
  pricesData: PricePreviewData | undefined,
  interval: SubscriptionIntervals = SubscriptionIntervals.month
): {
  price: string;
  discountedPrice: string | undefined;
} {
  let price: number;
  let discountedPrice: number | undefined;

  const lineItems = pricesData?.details.lineItems;
  const priceDetails = lineItems?.find((item) => item.price.id === priceId);
  const priceInterval = PRICE_ID_TO_INTERVAL_MAP[priceId];

  const isDiscountApplied = !!priceDetails?.discounts.length;

  if (!!priceDetails) {
    price = isDiscountApplied
      ? parseInt(priceDetails.totals.subtotal, 10) / 100
      : parseInt(priceDetails.totals.total, 10) / 100;
    discountedPrice = isDiscountApplied
      ? parseInt(priceDetails.totals.total, 10) / 100
      : undefined;
  } else {
    price = PRICE_ID_TO_PRICE_MAP[priceId] / 100;
  }

  if (priceInterval !== interval) {
    if (interval === SubscriptionIntervals.month) {
      price = price / 12;
      discountedPrice = !!discountedPrice ? discountedPrice / 12 : undefined;
    } else {
      price = price * 12;
      discountedPrice = !!discountedPrice ? discountedPrice * 12 : undefined;
    }
  }

  const currencyCode = pricesData?.currencyCode || "USD";
  const formattedPrice = formatPrice(price, currencyCode);
  const formattedDiscountedPrice =
    isDiscountApplied && discountedPrice
      ? formatPrice(discountedPrice, currencyCode)
      : undefined;

  return { price: formattedPrice, discountedPrice: formattedDiscountedPrice };
}
