import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Cookies from "js-cookie";
import { useSession } from "next-auth/react";

import Dialog from "@musicfy/components/Dialog";
import Icon from "@musicfy/components/Icon";
import StartCheckoutFlowButton from "@musicfy/components/PaymentButtons/StartCheckoutFlowButton";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

import Card from "../Card";

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
const BANNER_COOKIE = "hasDimissedFreeTrialBanner";

const DiscountBanner = (): JSX.Element | null => {
  const { data } = useSession();
  const user = data?.user;

  const { subscription, isLoading: isSubscriptionLoading } =
    useSubscriptionContext();
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [hasDismissed, setHasDismissed] = useState(false);
  const [remainingTime, setRemainingTime] = useState(ONE_DAY_IN_MS);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const isNewUser = !!user
    ? Date.now() - new Date(user.createdAt).getTime() < ONE_DAY_IN_MS * 2
    : false;

  const showBanner = useMemo(() => {
    return isBannerOpen && !hasDismissed && isNewUser && !subscription;
  }, [hasDismissed, isBannerOpen, isNewUser, subscription]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const _remainingTime =
      new Date(user.createdAt).getTime() + ONE_DAY_IN_MS * 2 - Date.now();
    setRemainingTime(_remainingTime);
  }, [user]);

  useEffect(() => {
    const _hasDimissedBanner = Cookies.get(BANNER_COOKIE) === "true";

    if (_hasDimissedBanner) {
      setHasDismissed(true);
    } else {
      setIsBannerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (showBanner) {
      timerRef.current = setTimeout(() => {
        setRemainingTime((prevTime) => {
          if (prevTime - 1000 <= 0) {
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    }

    return () => {
      if (!!timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [remainingTime, showBanner]);

  const handleClose = useCallback(() => {
    setHasDismissed(true);
    setIsBannerOpen(false);
    Cookies.set(BANNER_COOKIE, "true", { expires: 7 });
  }, []);

  if (!showBanner || isSubscriptionLoading) {
    return null;
  }

  const remainingDays = Math.floor(remainingTime / ONE_DAY_IN_MS);
  const remainingHours = Math.floor(
    (remainingTime % ONE_DAY_IN_MS) / (60 * 60 * 1000)
  );
  const remainingMinutes = Math.floor(
    (remainingTime % (60 * 60 * 1000)) / (60 * 1000)
  );
  const remainingSeconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  return (
    <Dialog
      open={showBanner}
      onClose={handleClose}
      title="Limited Time Offer"
      TitleIcon={<AccessTimeIcon />}
      width="450px"
      primaryButton={
        <StartCheckoutFlowButton origin="free-trial-banner" fullWidth>
          Upgrade Today
        </StartCheckoutFlowButton>
      }
    >
      <div className="font-bold text-28">Save 50% on your first month!</div>
      <div className="mt-4 text-white/90 font-semibold">
        Upgrade today to unlock
      </div>
      <div className="mt-6 grid lg:grid-cols-2 grid-cols-1 gap-4">
        <div className="flex items-center gap-3">
          <Icon name="rotation" className="w-5" />
          <div>Unlimited Generations</div>
        </div>
        <div className="flex items-center gap-3">
          <Icon name="sound" className="w-5" />
          <div>Premium Sound</div>
        </div>
        <div className="flex items-center gap-3">
          <Icon name="cube" className="w-5" />
          <div>Custom Voices</div>
        </div>
        <div className="flex items-center gap-3">
          <Icon name="rocket" className="w-5" />
          <div>Faster Speeds</div>
        </div>
      </div>
      <div className="grid grid-cols-4 lg:gap-4 gap-[6px] mt-8">
        <Card>
          <div className="flex flex-col justify-center items-center mx-auto">
            <div className="text-32 font-bold">{remainingDays}</div>
            <div className="text-12 mt-1 tracking-wide text-white/80 text-center">
              Days
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex flex-col justify-center items-center mx-auto">
            <div className="text-32 font-bold">{remainingHours}</div>
            <div className="text-12 mt-1 tracking-wide text-white/80 text-center">
              Hours
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex flex-col justify-center items-center mx-auto">
            <div className="text-32 font-bold">{remainingMinutes}</div>
            <div className="text-12 mt-1 tracking-wide text-white/80 text-center">
              Minutes
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex flex-col justify-center items-center mx-auto">
            <div className="text-32 font-bold">{remainingSeconds}</div>
            <div className="text-12 mt-1 tracking-wide text-white/80 text-center">
              Seconds
            </div>
          </div>
        </Card>
      </div>
    </Dialog>
  );
};

export default DiscountBanner;
