import React, { createContext, useContext } from "react";

import { api } from "@musicfy/utils";
import { useArray } from "@musicfy/utils/hooks";

import { type IModel } from "./IModels";

export interface IModelsContext {
  models: IModel[];
  isLoading: boolean;
  setModels: React.Dispatch<React.SetStateAction<IModel[]>>;
}

const ModelsContext = createContext<IModelsContext | null>(null);

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

const TRENDING_SCORE_MULTIPLIER = 0.5;
const DATE_SCORE_MULTIPLIER = 0.5;

function calculateAverageTrendingUsageCount(models: IModel[]): number {
  return (
    models.reduce((acc, model) => acc + (model.trendingUsageCount || 0), 0) /
    models.length
  );
}

function calculateStandardDeviation(models: IModel[]): number {
  const averageTrendingUsageCount =
    models.reduce((acc, model) => acc + (model.trendingUsageCount || 0), 0) /
    models.length;

  return Math.sqrt(
    models.reduce(
      (acc, model) =>
        acc +
        Math.pow(
          (model.trendingUsageCount || 0) - averageTrendingUsageCount,
          2
        ),
      0
    ) /
      models.length -
      1
  );
}

function normalizeTrendingUsageCount(models: IModel[]): IModel[] {
  const average = calculateAverageTrendingUsageCount(
    models.filter((model) => !!model.trendingUsageCount)
  );
  const standardDeviation = calculateStandardDeviation(
    models.filter((model) => !!model.trendingUsageCount)
  );

  return models.map((model) => ({
    ...model,
    trendingUsageCount: model.trendingUsageCount
      ? (model.trendingUsageCount || 0 - average) / standardDeviation
      : 0,
  }));
}

function generateTrendingScore(model: IModel): number {
  const normalizedTrendingUsage = model.trendingUsageCount;
  if (!normalizedTrendingUsage) {
    return 0;
  }

  let normalizedDateValue = 0;
  if (new Date(model.createdAt).getTime() < Date.now() - ONE_DAY_IN_MS * 14) {
    normalizedDateValue = 0;
  } else {
    const differenceInDays =
      (Date.now() - new Date(model.createdAt).getTime()) / ONE_DAY_IN_MS;
    normalizedDateValue = (14 - differenceInDays) / 14;
  }

  const trendingScore =
    normalizedTrendingUsage * TRENDING_SCORE_MULTIPLIER +
    normalizedDateValue * DATE_SCORE_MULTIPLIER;

  return trendingScore;
}

export function useModelsContext() {
  const modelsContext = useContext(ModelsContext);

  if (!modelsContext) {
    throw new Error("useModelsContext must be used within a ModelsProvider");
  }

  return modelsContext;
}

const ModelsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { value: models, setValue: setModels } = useArray<IModel>([]);

  const getModels = api.models.getModels.useQuery(undefined, {
    onSuccess: (data) => {
      if (!data) {
        return;
      }

      const normalizedModels = normalizeTrendingUsageCount(data);
      const modelsWithTrendingScore = normalizedModels.map((model) => {
        return {
          ...model,
          trendingScore: !!model.trendingUsageCount
            ? generateTrendingScore(model)
            : 0,
        };
      });
      setModels(modelsWithTrendingScore);
    },
    refetchOnWindowFocus: false,
  });

  const modelsContextValue = {
    models: models,
    setModels: setModels,
    isLoading: getModels.isLoading,
  };

  return (
    <ModelsContext.Provider value={modelsContextValue}>
      {children}
    </ModelsContext.Provider>
  );
};

export default ModelsProvider;
