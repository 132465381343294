import { useCallback, useState } from "react";

import { SubscriptionStatus } from "@prisma/client";
import { usePostHog } from "posthog-js/react";

import Button, { type IButtonProps } from "@musicfy/components/Button";
import Dialog from "@musicfy/components/Dialog";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

import CancellationWizard from "./CancellationWizard";
import { type Reason } from "./constants";

const CancelSubscriptionButton: React.FC<IButtonProps> = ({
  ...buttonProps
}) => {
  const posthog = usePostHog();

  const { subscription, renewSubscription, resumeSubscription } =
    useSubscriptionContext();

  const [isRenewalDialogOpen, setIsRenewalDialogOpen] = useState(false);
  const [isResumeDialogOpen, setIsResumeDialogOpen] = useState(false);
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false);

  const [isRenewing, setIsRenewing] = useState(false);
  const [isResuming, setIsResuming] = useState(false);

  const [cancellationReason, setCancellationReason] = useState<Reason | string>(
    ""
  );

  const handleCancelSubscriptionClick = useCallback(() => {
    if (!subscription) {
      return;
    }
    setIsCancellationDialogOpen(true);
    posthog.capture("Subscription Cancellation Wizard Viewed", {
      plan: subscription?.plan,
      interval: subscription?.interval,
    });
  }, [posthog, subscription]);

  const onRenewSubscription = useCallback(async () => {
    if (!subscription) {
      return;
    }
    setIsRenewing(true);
    await renewSubscription();
    setIsRenewing(false);
    setIsRenewalDialogOpen(false);
  }, [renewSubscription, subscription]);

  const onResumeSubscription = useCallback(async () => {
    if (!subscription) {
      return;
    }

    setIsResuming(true);
    await resumeSubscription();
    setIsResuming(false);
    setIsResumeDialogOpen(false);
    setIsCancellationDialogOpen(false);
  }, [resumeSubscription, subscription]);

  if (!subscription) {
    return null;
  }

  const isSubscriptionSetToCancel = !!subscription.canceledAt;
  const isSubscriptionPaused =
    subscription.status === SubscriptionStatus.paused;

  if (isSubscriptionSetToCancel) {
    const renewalDate = subscription?.periodEnd
      ? new Date(subscription.periodEnd).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : null;

    return (
      <>
        <Button
          variant="text"
          style="tertiary"
          {...buttonProps}
          onClick={() => setIsRenewalDialogOpen(true)}
        >
          Renew Subscription
        </Button>
        <Dialog
          open={isRenewalDialogOpen}
          onClose={() => setIsRenewalDialogOpen(false)}
          maxWidth="sm"
          title="Renew Subscription"
          subtitle={`Are you sure you want to renew your subscription? You will be billed again on ${
            renewalDate || ""
          }.`}
          primaryButton={
            <Button
              onClick={onRenewSubscription}
              fullWidth
              loading={isRenewing}
            >
              Renew Subscription
            </Button>
          }
          secondaryButton={
            <Button
              style="solid"
              className="!bg-gray-900"
              onClick={() => setIsRenewalDialogOpen(false)}
              fullWidth
            >
              Cancel
            </Button>
          }
        />
      </>
    );
  }

  if (isSubscriptionPaused) {
    return (
      <>
        <Button
          variant="text"
          style="tertiary"
          {...buttonProps}
          onClick={() => setIsResumeDialogOpen(true)}
        >
          Resume Subscription
        </Button>
        <Dialog
          open={isResumeDialogOpen}
          onClose={() => setIsResumeDialogOpen(false)}
          maxWidth="sm"
          title="Resume Subscription"
          subtitle={`Are you sure you want to resume your subscription? Your subscription will continue to renew automatically.`}
          primaryButton={
            <Button
              onClick={() => onResumeSubscription()}
              fullWidth
              loading={isResuming}
            >
              Resume Subscription
            </Button>
          }
          secondaryButton={
            <Button
              style="solid"
              className="!bg-gray-900"
              onClick={() => setIsResumeDialogOpen(false)}
              fullWidth
            >
              Cancel
            </Button>
          }
        />
      </>
    );
  }

  return (
    <>
      <Button
        variant="text"
        style="tertiary"
        onClick={() => handleCancelSubscriptionClick()}
        {...buttonProps}
      >
        Cancel Subscription
      </Button>
      <CancellationWizard
        isOpen={isCancellationDialogOpen}
        onClose={() => setIsCancellationDialogOpen(false)}
        cancellationReason={cancellationReason}
        setCancellationReason={setCancellationReason}
      />
    </>
  );
};

export default CancelSubscriptionButton;
