import { type IIconName } from "../Icon";

export function findActiveSection(
  url: string,
  sections: IFeatureInfo[]
): IFeatureInfo | undefined {
  let closestSection: IFeatureInfo | undefined = undefined;
  let maxCommonSegments = 0;

  for (const section of sections) {
    const pathSegments = section.route.split("/");
    const urlSegments = url.split("/");

    let commonSegments = 0;

    for (
      let i = 0;
      i < Math.min(pathSegments.length, urlSegments.length);
      i++
    ) {
      if (pathSegments[i] === urlSegments[i]) {
        commonSegments++;
      } else {
        break;
      }
    }

    if (commonSegments > maxCommonSegments && commonSegments > 2) {
      maxCommonSegments = commonSegments;
      closestSection = section;
    }
  }

  return closestSection;
}

export enum EApps {
  DASHBOARD = "dashboard",

  /* Sidebar */
  CREATE = "create",
  PRO_TOOLS = "pro-tools",
  MANAGE = "manage",
  HELP = "help",
  GUIDE = "guide",
  API = "api",

  /* External */
  AUTH = "auth",

  /* Internal */
  ADMIN = "admin",
}

export enum EFeatures {
  AFFILIATE = "affiliate",
  DISCORD = "discord",
  FAQ = "faq",
  HISTORY = "history",
  LIBRARY = "library",
  LOGIN = "login",
  REPORT_BUG = "report-bug",
  SIGN_UP = "signup",
  TEXT_TO_MUSIC = "text-to-music",
  TIPS = "tips",
  TUTORIALS = "tutorials",
  VERIFY_REQUEST = "verify-request",
  VOICE = "voice",
  VOICES = "voices",
  STEM_SPLITTER = "stem-splitter",
  TOKENS = "tokens",
  USAGE = "usage",
  API_BILLING = "api-billing",
  API_DOCS = "api-docs",

  /* Internal */
  UPLOAD_MODEL = "upload-model",
}

export interface IAppInfo {
  key: EApps;
  displayName: string;
  subtitle?: string;
  icon: IIconName;
  mainRoute: string;
  route: string;
  features: IFeatureInfo[];
  isAuthProtected?: boolean;
  isAdminProtected?: boolean;
  isPremium?: boolean;
  isNew?: boolean;
}

export interface IFeatureInfo {
  key: string;
  displayName: string;
  subtitle?: string;
  route: string;
  isAuthProtected?: boolean;
  isAdminProtected?: boolean;
  isPremium?: boolean;
  isNew?: boolean;
}

export const APP_ROUTES: Record<EApps, string> = {
  [EApps.DASHBOARD]: "/",
  [EApps.CREATE]: "/create",
  [EApps.PRO_TOOLS]: "/pro-tools",
  [EApps.MANAGE]: "/manage",
  [EApps.HELP]: "/support",
  [EApps.AUTH]: "/auth",
  [EApps.GUIDE]: "",
  [EApps.API]: "/api-access",

  /* Internal */
  [EApps.ADMIN]: "/admin",
};

export const FEATURE_ROUTES: Record<EFeatures, string> = {
  [EFeatures.AFFILIATE]: "https://musicfy.tolt.io/login",
  [EFeatures.DISCORD]: "https://discord.gg/BHVtHCFmew",
  [EFeatures.FAQ]: `${APP_ROUTES[EApps.HELP]}/faq`,
  [EFeatures.HISTORY]: `${APP_ROUTES[EApps.MANAGE]}/history`,
  [EFeatures.LIBRARY]: `${APP_ROUTES[EApps.MANAGE]}/library`,
  [EFeatures.LOGIN]: `${APP_ROUTES[EApps.AUTH]}/login`,
  [EFeatures.REPORT_BUG]: "https://musicfy.lol/contact",
  [EFeatures.SIGN_UP]: `${APP_ROUTES[EApps.AUTH]}/signup`,
  [EFeatures.TEXT_TO_MUSIC]: `${APP_ROUTES[EApps.CREATE]}/text-to-music`,
  [EFeatures.TIPS]: `https://musicfy.notion.site/Musicfy-V2-Quick-Tips-a4971710e9a04c9984b60fa5e8d4f303?pvs=4`,
  [EFeatures.TUTORIALS]: `https://musicfy.lol/tutorial`,
  [EFeatures.VERIFY_REQUEST]: `${APP_ROUTES[EApps.AUTH]}/verify-request`,
  [EFeatures.VOICE]: `${APP_ROUTES[EApps.CREATE]}/voice`,
  [EFeatures.VOICES]: `${APP_ROUTES[EApps.MANAGE]}/voices`,
  [EFeatures.STEM_SPLITTER]: `${APP_ROUTES[EApps.PRO_TOOLS]}/stem-splitter`,
  [EFeatures.TOKENS]: `${APP_ROUTES[EApps.API]}/tokens`,
  [EFeatures.USAGE]: `${APP_ROUTES[EApps.API]}/usage`,
  [EFeatures.API_BILLING]: `${APP_ROUTES[EApps.API]}/billing`,
  [EFeatures.API_DOCS]: "https://docs.musicfy.lol",

  /* Internal */
  [EFeatures.UPLOAD_MODEL]: `${APP_ROUTES[EApps.ADMIN]}/upload-model`,
};
export const FEATURE_INFO: Partial<Record<EFeatures, IFeatureInfo>> = {
  [EFeatures.DISCORD]: {
    key: EFeatures.DISCORD,
    displayName: "Join Discord",
    subtitle: "Join Discord",
    route: FEATURE_ROUTES[EFeatures.DISCORD],
  },
  [EFeatures.FAQ]: {
    key: EFeatures.FAQ,
    displayName: "FAQ",
    subtitle:
      "Get answers to the most common questions about using Musicfy AI.",
    route: FEATURE_ROUTES[EFeatures.FAQ],
  },
  [EFeatures.HISTORY]: {
    key: EFeatures.HISTORY,
    displayName: "My History",
    subtitle: "View all of your generated Musicfy content.",
    route: FEATURE_ROUTES[EFeatures.HISTORY],
    isAuthProtected: true,
  },
  [EFeatures.LIBRARY]: {
    key: EFeatures.LIBRARY,
    displayName: "My Library",
    subtitle: "Manage your library of generated Musicfy content.",
    route: FEATURE_ROUTES[EFeatures.LIBRARY],
    isAuthProtected: true,
  },
  [EFeatures.REPORT_BUG]: {
    key: EFeatures.REPORT_BUG,
    displayName: "Report a Bug",
    subtitle: "Report a bug on Musicfy AI to help us improve our service.",
    route: FEATURE_ROUTES[EFeatures.REPORT_BUG],
  },
  [EFeatures.TEXT_TO_MUSIC]: {
    key: EFeatures.TEXT_TO_MUSIC,
    displayName: "Text to Music",
    subtitle: "Generate music from text using Musicfy's text to music engine.",
    route: FEATURE_ROUTES[EFeatures.TEXT_TO_MUSIC],
  },
  [EFeatures.STEM_SPLITTER]: {
    key: EFeatures.STEM_SPLITTER,
    displayName: "Stem Splitter",
    subtitle: "Split stems using Musicfy's Pro Tools.",
    route: FEATURE_ROUTES[EFeatures.STEM_SPLITTER],
    isPremium: true,
    isAuthProtected: true,
  },
  [EFeatures.TIPS]: {
    key: EFeatures.TIPS,
    displayName: "Quick Tips",
    subtitle: "Quick Tips",
    route: FEATURE_ROUTES[EFeatures.TIPS],
  },
  [EFeatures.TUTORIALS]: {
    key: EFeatures.TUTORIALS,
    displayName: "Watch Tutorials",
    subtitle: "Watch Tutorials",
    route: FEATURE_ROUTES[EFeatures.TUTORIALS],
  },
  [EFeatures.VOICE]: {
    key: EFeatures.VOICE,
    displayName: "Convert Voice",
    subtitle: "Convert your voice to any artist.",
    route: FEATURE_ROUTES[EFeatures.VOICE],
  },
  [EFeatures.VOICES]: {
    key: EFeatures.VOICES,
    displayName: "My Voices",
    subtitle: "Manage your created Musicfy voices.",
    route: FEATURE_ROUTES[EFeatures.VOICES],
    isAuthProtected: true,
    isPremium: true,
  },
  [EFeatures.TOKENS]: {
    key: EFeatures.TOKENS,
    displayName: "Tokens",
    subtitle: "Manage your api tokens.",
    route: FEATURE_ROUTES[EFeatures.TOKENS],
    isAuthProtected: true,
    isPremium: false,
  },
  [EFeatures.USAGE]: {
    key: EFeatures.USAGE,
    displayName: "Usage",
    subtitle: "View your api usage.",
    route: FEATURE_ROUTES[EFeatures.USAGE],
    isAuthProtected: true,
    isPremium: false,
  },
  [EFeatures.API_BILLING]: {
    key: EFeatures.API_BILLING,
    displayName: "Billing",
    subtitle: "Manage api billing.",
    route: FEATURE_ROUTES[EFeatures.API_BILLING],
    isAuthProtected: true,
    isPremium: false,
  },
  [EFeatures.API_DOCS]: {
    key: EFeatures.API_DOCS,
    displayName: "Docs",
    subtitle: "API Docs",
    route: FEATURE_ROUTES[EFeatures.API_DOCS],
  },

  /* Internal */
  [EFeatures.UPLOAD_MODEL]: {
    key: EFeatures.UPLOAD_MODEL,
    displayName: "Upload Model",
    subtitle: "Upload Model",
    route: FEATURE_ROUTES[EFeatures.UPLOAD_MODEL],
    isAdminProtected: true,
  },
};

export const APP_INFO: Partial<Record<EApps, IAppInfo>> = {
  [EApps.CREATE]: {
    key: EApps.CREATE,
    displayName: "Create",
    subtitle: "Create your own AI generated music",
    icon: "music-note",
    mainRoute: FEATURE_ROUTES[EFeatures.VOICE],
    route: APP_ROUTES[EApps.CREATE],
    features: [
      FEATURE_INFO[EFeatures.VOICE],
      FEATURE_INFO[EFeatures.TEXT_TO_MUSIC],
    ] as IFeatureInfo[],
  },
  [EApps.PRO_TOOLS]: {
    key: EApps.PRO_TOOLS,
    displayName: "Pro Tools",
    subtitle: "Create your own AI generated music",
    icon: "music-menu",
    mainRoute: FEATURE_ROUTES[EFeatures.STEM_SPLITTER],
    route: APP_ROUTES[EApps.PRO_TOOLS],
    features: [FEATURE_INFO[EFeatures.STEM_SPLITTER]] as IFeatureInfo[],
  },
  [EApps.MANAGE]: {
    key: EApps.MANAGE,
    displayName: "Manage",
    subtitle: "Manage your musicfy assets",
    icon: "manage",
    mainRoute: FEATURE_ROUTES[EFeatures.VOICES],
    route: APP_ROUTES[EApps.MANAGE],
    features: [
      FEATURE_INFO[EFeatures.LIBRARY],
      FEATURE_INFO[EFeatures.HISTORY],
      FEATURE_INFO[EFeatures.VOICES],
    ] as IFeatureInfo[],
  },
  [EApps.API]: {
    key: EApps.API,
    displayName: "API",
    subtitle: "Api access for musicfy",
    icon: "api",
    mainRoute: FEATURE_ROUTES.usage,
    route: APP_ROUTES[EApps.API],
    features: [
      FEATURE_INFO.usage,
      FEATURE_INFO.tokens,
      FEATURE_INFO["api-billing"],
      FEATURE_INFO["api-docs"],
    ] as IFeatureInfo[],
    isNew: true,
  },
  [EApps.HELP]: {
    key: EApps.HELP,
    displayName: "Help",
    subtitle: "Get support from the musicfy community",
    icon: "help",
    mainRoute: FEATURE_ROUTES[EFeatures.FAQ],
    route: APP_ROUTES[EApps.HELP],
    features: [
      FEATURE_INFO[EFeatures.FAQ],
      FEATURE_INFO[EFeatures.REPORT_BUG],
      FEATURE_INFO[EFeatures.DISCORD],
    ] as IFeatureInfo[],
  },
  [EApps.GUIDE]: {
    key: EApps.GUIDE,
    displayName: "Guide",
    subtitle: "Tips and tricks for musicfy",
    icon: "guide",
    mainRoute: FEATURE_ROUTES.tips,
    route: APP_ROUTES[EApps.GUIDE],
    features: [FEATURE_INFO.tips, FEATURE_INFO.tutorials] as IFeatureInfo[],
  },

  /* Internal */
  [EApps.ADMIN]: {
    key: EApps.ADMIN,
    displayName: "Admin",
    subtitle: "Admin",
    icon: "crown",
    mainRoute: FEATURE_ROUTES[EFeatures.UPLOAD_MODEL],
    route: APP_ROUTES[EApps.ADMIN],
    features: [FEATURE_INFO[EFeatures.UPLOAD_MODEL]] as IFeatureInfo[],
    isAdminProtected: true,
  },
};

export const NON_AUTH_REQUIRED_ROUTES = [
  "/",
  FEATURE_ROUTES.voice,
  FEATURE_ROUTES.faq,
  FEATURE_ROUTES.login,
  FEATURE_ROUTES.signup,
  FEATURE_ROUTES["verify-request"],
];

export const APPS = Object.values(APP_INFO);
