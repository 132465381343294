import DiscountBanner from "./DiscountBanner";
import FailedPaymentBanner from "./FailedPaymentBanner";

const NotificationBanners = (): JSX.Element | null => {
  return (
    <>
      <DiscountBanner />
      <FailedPaymentBanner />
    </>
  );
};

export default NotificationBanners;
