import React, { useEffect } from "react";

import { SubscriptionPlans } from "@prisma/client";
import Lottie from "lottie-react";
import { type AppProps } from "next/app";
import Head from "next/head";
import { type Session } from "next-auth";
import { SessionProvider, useSession } from "next-auth/react";
import { posthog as posthogClient } from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";

import PlayMusic from "@musicfy/assets/animations/play-music.json";
import PageLoader from "@musicfy/components/PageLoader";
import { env } from "@musicfy/env.mjs";
import NavigationLayout from "@musicfy/layouts/NavigationLayout";
import ApiAccessProvider, {
  useApiAccessContext,
} from "@musicfy/libs/ApiAccessProvider";
import AudioGenerationsProvider from "@musicfy/libs/AudioGenerationsProvider";
import FFmpegProvider from "@musicfy/libs/FFmpegProvider";
import FreeUsageCounterContext from "@musicfy/libs/FreeUsageCounterProvider";
import GlobalAudioPlayerProvider from "@musicfy/libs/GlobalAudioPlayerProvider";
import ModelsProvider, { useModelsContext } from "@musicfy/libs/ModelsProvider";
import MusicGenerationsProvider from "@musicfy/libs/MusicGenerationsProvider";
import PaddleProvider from "@musicfy/libs/PaddleProvider";
import RequireAuthProvider from "@musicfy/libs/RequireAuthProvider";
import StemGenerationsProvider from "@musicfy/libs/StemGenerationsProvider";
import SubscriptionProvider, {
  useSubscriptionContext,
} from "@musicfy/libs/SubscriptionProvider";
import ToastProvider from "@musicfy/libs/ToastProvider";
import ToltLoader from "@musicfy/scripts/ToltLoader";
import { api } from "@musicfy/utils/api";

import "@musicfy/styles/globals.css";

if (typeof window !== "undefined") {
  if (env.NEXT_PUBLIC_VERCEL_ENV !== "preview") {
    const baseUrl =
      env.NEXT_PUBLIC_VERCEL_ENV === "development"
        ? "http://localhost:3000"
        : "https://create.musicfy.lol";

    posthogClient.init("phc_b1IjIo9iTOKwDxiCve7abg82mC4SpKQQqz8hDe1qElR", {
      api_host: `${baseUrl}/ingest`,
      autocapture: false,
      disable_session_recording: true,
      disable_scroll_properties: true,
    });
  }
}

type IAppProps = {
  Component: INextPage;
  pageProps: AppProps & { session: Session | null };
};

type IComponentProps = {
  Component: INextPage;
  pageProps: AppProps;
};

const MusicfyComponent = ({
  Component,
  pageProps,
}: IComponentProps): JSX.Element => {
  const posthog = usePostHog();
  const { data } = useSession();
  const user = data?.user;

  const { subscription, isLoading: isSubscriptionLoading } =
    useSubscriptionContext();
  const { isLoading: isModelsLoading, models } = useModelsContext();
  const { isLoading: isApiAccessLoading } = useApiAccessContext();

  useEffect(() => {
    if (!isSubscriptionLoading && !!user) {
      posthog?.identify(
        user.id, // distinct_id, required
        { subscription: subscription?.plan ?? SubscriptionPlans.free }, // $set, optional
        { email: user.email } // $set_once, optional
      );

      if (!!window.tolt_referral) {
        window.tolt.signup(user.email || "");
      }
    }
  }, [user, posthog, isSubscriptionLoading, subscription?.plan]);

  const shouldRankForSEO = Component.seo ?? false;
  const isLoading =
    (isModelsLoading ||
      isSubscriptionLoading ||
      !models.length ||
      isApiAccessLoading) &&
    !shouldRankForSEO;

  if (isLoading) {
    return <PageLoader />;
  }

  return <Component {...pageProps} />;
};

const Musicfy = ({ Component, pageProps }: IComponentProps): JSX.Element => {
  const { status } = useSession();

  const Layout = Component.layout ?? NavigationLayout;
  const shouldRankForSEO = Component.seo ?? false;

  const isLoading = status === "loading" && !shouldRankForSEO;

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Lottie
          color="pink"
          animationData={PlayMusic as unknown}
          style={{
            width: 200,
            height: 200,
          }}
        />
      </div>
    );
  }

  return (
    <RequireAuthProvider>
      <SubscriptionProvider>
        <PaddleProvider>
          <FFmpegProvider>
            <ModelsProvider>
              <ApiAccessProvider>
                <AudioGenerationsProvider>
                  <StemGenerationsProvider>
                    <MusicGenerationsProvider>
                      <FreeUsageCounterContext>
                        <GlobalAudioPlayerProvider>
                          <Layout>
                            <MusicfyComponent
                              Component={Component}
                              pageProps={pageProps}
                            />
                          </Layout>
                        </GlobalAudioPlayerProvider>
                      </FreeUsageCounterContext>
                    </MusicGenerationsProvider>
                  </StemGenerationsProvider>
                </AudioGenerationsProvider>
              </ApiAccessProvider>
            </ModelsProvider>
          </FFmpegProvider>
        </PaddleProvider>
      </SubscriptionProvider>
    </RequireAuthProvider>
  );
};

const Background = () => {
  return (
    <>
      <div className="circle-1 blur-3xl" />
      <div className="circle-2 blur-3xl" />
      <div className="fixed z-[1] w-full h-full bg-white/2" />
    </>
  );
};

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: IAppProps) => {
  return (
    <SessionProvider session={session}>
      <Background />
      <Head>
        <title>Musicfy</title>
      </Head>
      <ToltLoader />
      <PostHogProvider client={posthogClient}>
        <ToastProvider>
          <Musicfy Component={Component} pageProps={pageProps} />
        </ToastProvider>
      </PostHogProvider>
    </SessionProvider>
  );
};

export default api.withTRPC(MyApp);
