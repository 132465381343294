import { useCallback, useEffect, useState } from "react";

import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { usePostHog } from "posthog-js/react";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import Dropdown from "@musicfy/components/Dropdown";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

import { Step } from "../constants";

interface ConfirmPauseSubscriptionProps {
  setStep: (step: Step) => void;
  onClose: () => void;
}

const PAUSE_OPTIONS = [
  { label: "1 Month", value: 1 },
  { label: "2 Months", value: 2 },
  { label: "3 Months", value: 3 },
];

const ConfirmPauseSubscription = ({
  setStep,
  onClose,
}: ConfirmPauseSubscriptionProps): JSX.Element => {
  const posthog = usePostHog();

  const { subscription, pauseSubscription } = useSubscriptionContext();

  const [isPausing, setIsPausing] = useState(false);
  const [pauseDuration, setPauseDuration] = useState<number>(
    PAUSE_OPTIONS[0]?.value || 1
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const pauseSubsription = useCallback(async () => {
    if (!subscription) {
      return;
    }
    setIsPausing(true);
    setErrorMessage("");
    try {
      await pauseSubscription(pauseDuration);
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage("Something went wrong. Please try again later.");
    }
    setIsPausing(false);
  }, [onClose, pauseDuration, pauseSubscription, subscription]);

  useEffect(() => {
    posthog.capture("Subscription Cancel Step Viewed", {
      plan: subscription?.plan,
      interval: subscription?.interval,
      step: "confirm-pause",
    });
  }, [posthog, subscription?.interval, subscription?.plan]);

  return (
    <div className="relative">
      <div className="bg-white/3 relative flex items-center overflow-hidden gap-3 text-white p-3">
        <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
        <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
        <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
        <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
        <PauseCircleIcon className="!text-20 relative z-20" />
        <div className="font-bold relative z-20">Pause Subscription</div>
      </div>
      <div className="px-5 mt-6">
        <div className="font-bold text-22">
          Need Musicfy AI in the future? You can take a pause.
        </div>
        <div className="mt-3 text-gray-300 tracking-wide">
          Would you like to pause your subscription for a while?
        </div>
      </div>
      <div className="px-5 mt-8">
        <div className="mb-2 tracking-wide">
          Choose how long you want to pause...
        </div>
        <Dropdown
          value={pauseDuration}
          setValue={setPauseDuration}
          options={PAUSE_OPTIONS}
        />
        <Button
          className="mt-4"
          fullWidth
          loading={isPausing}
          onClick={pauseSubsription}
        >
          Pause Subscription
        </Button>
      </div>
      {!!errorMessage && (
        <div className="px-5 text-red mt-2 text-center">{errorMessage}</div>
      )}
      <Divider className="mb-4 mt-9" />
      <div className="px-3 pb-4 flex items-center justify-between">
        <Button
          variant="outlined"
          className="!outline-transparent !bg-transparent"
          style="secondary"
          onClick={() => {
            if (isPausing) {
              return;
            }
            setStep(Step.CANCEL_REASON);
          }}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          className="!outline-transparent"
          style="secondary"
          onClick={() => {
            if (isPausing) {
              return;
            }
            setStep(Step.CONFIRM);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ConfirmPauseSubscription;
